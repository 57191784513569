<template>
  <div class="up_login_container">
    <div class="up_login_box">
      <div class="up_left">
        <img src="https://console.staging.truabilities.com/assets/dist/img/login-graphic.png" alt="" class="up_graphic">
        <h1>USER PORTAL</h1>
      </div>
      <div class="up_right">
        <a href="https://console.staging.truabilities.com/" class="logo">
          <img src="https://console.staging.truabilities.com/assets/dist/img/truabilities-logo.png" class="truabilities-image" alt="Truabilities Image">
        </a>
        <div class="p-grid">
          <div class="p-col-12">
            <!-- login form end -->
            <form @submit.prevent="updatePassword('submit')" class="user_portal_login">
              <div class="p-fluid">
                <div class="p-field">
                  <label for="email">Email</label>
                  <InputText type="email" v-model="forgetPassValue.email" id="email" />
                  <small class="p-invalid text-danger" v-if="loginError.emailError">Email is not available.</small>
                  <small class="p-invalid text-danger" v-if="!loginError.emailError && loginError.emailInvalid">Email is not valid.</small>
                </div>
                <div class="p-field p-d-flex p-jc-between p-ai-center">
                    <Button type="submit" label="Sign in" class="btn-primary" />
                    <router-link to="/" class="up_forget_pasword">Login</router-link>
                </div>
                <div class="sup_login_massage text-danger" v-if="apiResponse.status !== 200 && apiResponse.status !== null">{{apiResponse.message}}</div >
              </div>
            </form>
            <!-- login form end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userPortalForgetPass } from '../../../config/appUrls';
import { apiKey } from "../../../config/constant";
import { validateEmail } from '../../../helper/validationProcess/ValidationProcess';
import { reactive } from 'vue';
import axios from "axios";

export default {
    name: 'ForgetPassword',
    setup(){
        const forgetPassValue = reactive({
            email: '',
            isSubmitted: false
        });

        const loginError = reactive({
            emailError: false,
            emailInvalid: false
        });

        const apiResponse = reactive({
            status: null,
            message: null
        });

        const updatePassword = async(location) => {
            const emailValid = validateEmail(forgetPassValue.email);
            if(!emailValid.lValidation){
                loginError.emailError = true;
                loginError.noEmailError = false;
            } else {
                loginError.emailError = false;
                loginError.noEmailError = true;
            }

            if(!emailValid.eValidation){
                loginError.emailInvalid = true;
                loginError.noEmailInvalid = false;
            } else {
                loginError.emailInvalid = false;
                loginError.noEmailInvalid = true;
            }

            if(!loginError.emailInvalid && !loginError.emailError){
                forgetPassValue.isSubmitted = true;
            }

            if(forgetPassValue.isSubmitted && location === 'submit'){
                try{
                    const response = await axios.post( userPortalForgetPass, {
                            email: forgetPassValue.email,
                        }, { 
                            headers: {
                                apiKey: apiKey
                            }
                        }
                    );

                    if(response.status === 200) {
                        apiResponse.status = response.data.status;
                        apiResponse.message = response.data.message;
                    }
                } catch(err) {
                    apiResponse.status = err.response.data.status;
                    apiResponse.message = err.response.data.message;
                }
            }
        }

        return{
            forgetPassValue,
            loginError,
            apiResponse,
            updatePassword
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/component/userportal/pages/Login.scss";
</style>